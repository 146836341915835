import { render, staticRenderFns } from "./_base-sidebar.vue?vue&type=template&id=4c56fbea&scoped=true&"
import script from "./_base-sidebar.vue?vue&type=script&lang=js&"
export * from "./_base-sidebar.vue?vue&type=script&lang=js&"
import style0 from "./_base-sidebar.vue?vue&type=style&index=0&id=4c56fbea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c56fbea",
  null
  
)

export default component.exports