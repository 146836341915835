<template>
  <div  class="rc-root-layout-header">
      <div class="Header py-3 ">
      <div class=" Header__menu">
        <div class="Page__width Header__menu__buttons">
          <div class="Header__menu__buttons__left d-flex align-items-center justify-content-start">

            <a v-if="site_settings && Object.keys(site_settings).length   > 0 "  class="Header__logo" href="/" title="Go to Home Page">
              <h3 style="font-size: 20px!important; font-weight: 600;" class="text-primary font-weight-bolder" >{{site_settings.name }}&nbsp;</h3>
            </a> 
          </div>
          <div class="Header__menu__buttons__right d-flex align-items-center justify-content-end ">
           
            <router-link :to="{name: 'Dashboard'  } "  v-if="user && Object.keys(user).length > 0" class="MenuButton d-sm-flex d-none MenuButton--orders-button cw-nav-menu-item" >
              <span class="MenuButton__icon"></span>
              <span class="MenuButton__title">My orders</span>
              <!-- <span class="MenuButton__short-title">Orders</span> -->
            </router-link>
            <router-link :to="{name: 'Login'  } " v-else class="MenuButton  MenuButton--sign-in-button cw-nav-menu-item"  >
            <span class="MenuButton__icon"></span>
            <span class="MenuButton__title">Sign In</span>
              <!-- <span class="MenuButton__short-title">Sign In</span> -->
            </router-link>

            <router-link :to="{name: 'PlaceOrder'  } " class="MenuButton MenuButton--new-order-button d-sm-flex d-none cw-nav-menu-item" >
              <span class="MenuButton__icon"></span>
              <span class="MenuButton__title">New order</span>
              <span class="MenuButton__short-title">Order</span>
            </router-link>
            <div v-if="user && Object.keys(user).length > 0" style="border-color:#fff!important;" class="dropdown d-inline-block Header__user-info  " data-toggle="dropdown" >
            <div 
              data-toggle="dropdown"
              class="UserBlock"
              
              >
              <button class="UserBlockWhois "></button>
              
              
            </div>
          
          <div   class="dropdown-menu  dropdown-menu-right pt-0">
            <router-link :to="{name: 'Profile'  } " class="dropdown-item" 
              ><i
                class="
                  bx bx-user-circle
                  text-muted
                  font-size-18
                  align-middle
                  me-1
                "
              ></i>
              <span class="align-middle">My Account</span>
              
            </router-link >
           
            <router-link :to="{name: 'Profile'  } " class="dropdown-item d-flex align-items-center" 
              ><i
                class="bx bx-cog text-muted font-size-18 align-middle me-1"
              ></i>
              <p class="align-middle me-3">Settings</p>
              <!-- <span class="badge badge-soft-success ms-auto">New</span> -->
            </router-link>
            <div class="dropdown-divider"></div>

            
            <a href="javascript:void(0)" class="dropdown-item" @click="doLogout"
              ><i
                class="bx bx-log-out text-muted font-size-18 align-middle me-1"
              ></i>
              <span class="align-middle">Logout</span></a
            >
          </div>
        </div>
        <!-- {{user}} -->

          
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="navbar navbar-expand-md navbar-dark">
    <div class="container">
      <div class="navbar-brand">
        <a href="/" class="d-inline-block">
          <img src="/logo.png" alt="Home"  height="42"/>
        </a>
      </div>

      <div class="d-md-none">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-mobile"
        >
          <i class="icon-tree5"></i>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navbar-mobile">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="navbar-nav-link" :to="{ name: 'PlaceOrder' }"
              >Place Order</router-link
            >
          </li>

          
          <li class="nav-item" v-if="isAuth">
            <router-link class="navbar-nav-link" :to="{ name: 'Dashboard' }"
              >Home</router-link
            >
          </li>
          <span v-if="isAuth">
            <li class="nav-item py-1">
              <button class="btn btn-secondary" @click="doLogout">Logout</button>
            </li>
          </span>
          <li class="nav-item" v-if="!isAuth">
            <router-link class="navbar-nav-link" :to="{ name: 'Login' }"
              >Login</router-link
            >
          </li>

          <li class="nav-item" v-if="!isAuth">
            <router-link class="navbar-nav-link" :to="{ name: 'Register' }"
              >Register</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    
  </div> -->
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Nav",
  computed: {
    ...mapState("auth", ["isAuthenticated", "user"]),
    ...mapState("setups", ["countries", "site_settings"]),

    isAuth() {
      if (this.isAuthenticated && this.user && Object.keys(this.user).length > 0) {
        return true
      } else return false
    }
  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
    ...mapActions("setups", ["getSetups","getSiteSettings", "getCountries"]),

  },

  async mounted() {
    this.$store.state.loading = true;
    this.getSiteSettings()
    this.$store.state.loading = false;
  },
};
</script>


<style scoped>
.navbar{
  z-index: 100;
}
</style>