export let baseMenuItems = [
    {
        text: 'Dashboard',
        icon: 'icon-home4',
        routeName: 'Dashboard',
    },

    // {
    //     text: 'Messages',
    //     icon: 'icon-envelope',
    //     routeName: 'Messages',
    // },

    {
        text: 'My Writers',
        icon: 'icon-users',
        routeName: 'MyWriters',
    },

    {
        text: 'Wallet',
        icon: 'icon-wallet',
        routeName: 'Wallet',
    },


    {
        text: 'Tips',
        icon: 'icon-wallet',
        routeName: 'Tips',
    },

    // {
    //     text: 'Earn from Refferals',
    //     icon: 'icon-link',
    //     routeName: 'EarnFromReferrals',
    // },
    {
        text: 'My Discounts',
        icon: 'icon-price-tag',
        routeName: 'MyDiscounts',
    },
];

export let actionMenuItems = [
    {
        text: 'Place Order',
        icon: 'icon-add',
        routeName: 'PlaceOrder',
    },
    // {
    //     text: 'Bulk Order',
    //     icon: 'icon-add',
    //     routeName: 'BulkOrder',
    // }
];
export let orderMenuItems = [
    { 
        text: 'Unpaid Orders',
        icon: 'icon-pencil',
        routeParam: 'pending',
    },
    { 
        text: 'In Progress',
        icon: 'icon-pencil',
        routeParam: 'inprogress',
    },
    { 
        text: 'Revision',
        icon: 'icon-pencil',
        routeParam: 'revision',
    },
    { 
        text: 'Editing',
        icon: 'icon-pencil',
        routeParam: 'editing',
    },
    { 
        text: 'Completed',
        icon: 'icon-pencil',
        routeParam: 'completed',
    },
    { 
        text: 'Approved Orders',
        icon: 'icon-pencil',
        routeParam: 'approved',
    },
    { 
        text: 'Cancelled Orders',
        icon: 'icon-pencil',
        routeParam: 'cancelled',
    },
    { 
        text: 'Rejected Orders',
        icon: 'icon-pencil',
        routeParam: 'rejected',
    },
    { 
        text: 'Disputed Orders',
        icon: 'icon-pencil',
        routeParam: 'disputed',
    },
];





