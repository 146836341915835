<template>
    <!-- Main sidebar -->
      
    <div class="t-page-leftbar  ">
       
      <aside id="aside_new"  class="left-menu aside_new    ">
      <nav class=" left-nav-menu navbar navbar-default">

      


      <div class="navbar-header">
      <!-- <div class="pull-right">
      <i class="material-icons menu pull-right blue-color">menu</i>
      <i class="material-icons menu-close pull-right blue-color">close</i>
      </div> --> 
      </div>
      <div class="navbar-collapse pt-2 ">
      <ul>
      <!-- <li 
      v-for="(item, index) in actionMenuItems"
      :key="index + 'bmi'"
      class="">
      
      <router-link
        :to="{ name: item.routeName }"
        class="nav-link"
        :class="showSidebar ? ' py-1' : ''"
        style="border-radius: 12px !important"
      >
        <i class="pr-2" :class="item.icon"></i>
        <span>{{ item.text }}</span>
        
      </router-link>
      </li> -->

      <li 
      v-for="(item, index) in baseMenuItems"
      :key="index + 'bmi'"
      class="">
      
      <router-link
        :to="{ name: item.routeName }"
        class="nav-link"
        :class="showSidebar ? ' py-1' : ''"
      >
        <i class="pr-2" :class="item.icon"></i>
        <span>{{ item.text }}</span>
        
      </router-link>
      </li>

      
      <li 
      v-show="show_orders"
      v-for="(item, index) in sidebarMenu"
      :key="index + 'bmi'"
      class=" ">
      <router-link

        @click="getSidebarMenu"
        :to="{ name: 'OrderListing', params: { status: item.value } }"
        class="nav-link  "
      >
        <div class="d-inline-block icons-sm mr-1">
          <!-- <i class="icon-pencil"></i> -->
          <img style="width:20px!important;" class="px-1" src="/orders/placeorder-solid2.svg" alt="toggle menu">

        </div>
        <div class="d-block w-100 text-left">
          
          <span class="text-left">{{ item.name }}</span>
        </div>
        <p
            class="badge badge-pill text-right"
            :class="`bg-${item.color || 'dark'}`"
            
            >{{ item.no || 0 }}</p>
      </router-link>
      <!-- {{item}} -->
      </li>

      <li 
      
      class="">
      
      <router-link
        :to="{ name: 'Profile'  }"
        class="nav-link"
        :class="showSidebar ? ' py-1' : ''"
      >
        <img style="width:23px!important; " class="pr-2" src="/orders/gear2-solid.svg" alt="setting">
        <span>Settings</span>
        
      </router-link>
      </li>

      </ul>
      
      </div>
      </nav>
      </aside>
      </div>

    <!-- /main sidebar -->
  </template>
  
  <script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import {
    actionMenuItems,
    baseMenuItems,
    orderMenuItems,
  } from "./sidebar.model";
  // import TipWriter from "./payments/modals/TipWriter.vue";
  
  export default {
    name: "ASidebar",
    components: {
      // TipWriter,
    },
    computed: {
      ...mapState("auth", ["user"]),
      ...mapState("sidebar", ["showSidebar", "sidebarMenu"]),
    },
    data() {
      return {
        show_orders:true,
        actionMenuItems: actionMenuItems,
        baseMenuItems: baseMenuItems,
        orderMenuItems: orderMenuItems,
      };
    },
    // beforeRouteUpdate() {
    //   this.getSidebarMenu()
    // },
    mounted() {
      this.getSidebarMenu();
    },
    methods: {
      ...mapActions("auth", ["doLogout"]),
      ...mapActions("sidebar", ["getSidebarMenu"]),
      ...mapMutations("sidebar", ["_toggleSidebarMobile"]),
      toggleOrders(){
        this.show_orders = !this.show_orders
        console.log("this is toggle orders===>", this.show_orders )

        
      },
      

    },
  };
  </script>
  
  
  <style lang="scss" scoped>
  .nav-item {
    max-height: 40px !important;
    height: 40px !important;
  }
  .nav-link {
    padding: 8px 10px !important;
  }
  .sidebar {
    .nav-link {
      padding: 0.5rem 0.8rem;
    }
  }
  .modal-backdrop {
    z-index: 1 !important;
  }
  
  .sidebar-xs .sidebar-main .nav-sidebar > .nav-item > .nav-link {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    max-height: 40px !important;
  }
  .nav-link.active.just-active {
    background-color: rgb(225,228,230) !important;
    // border-radius: 0px 20px 20px 0px;
    transition: 0.3s all ease-in-out;
  }
  
  .sidebar-xs .sidebar {
    a.nav-link i {
      font-size: 22px;
      padding: 6px;
    }
  
    a.nav-link.active.just-active {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      margin-top: 4px !important;
      margin-bottom: 4px !important;
    }
  }

  .sidebar:not(.sidebar-component) {
    position: relative!important;
    /* top: 0; */
    /* bottom: 0; */
    box-sizing: border-box!important;
    transition: all ease-in-out 0.15s;
}
  </style>