<template>
  <!-- Main sidebar -->
  <div
  
    class="sidebar d-sm-none sidebar-dark sidebar-main sidebar-fixed sidebar-expand-md"
  >
    <!-- Sidebar mobile toggler -->
    <div class="sidebar-mobile-toggler text-center">
      <a @click="_toggleSidebarMobile" class="sidebar-mobile-main-toggle">
        <i class="icon-arrow-left8"></i>
      </a>
      Navigation
      <a href="#" class="sidebar-mobile-expand">
        <!-- <i class="icon-screen-full"></i>
        <i class="icon-screen-normal"></i> -->
      </a>
    </div>
    <!-- /sidebar mobile toggler -->

    <!-- Sidebar content -->
    <div class="sidebar-content">
      <!-- User menu -->
      <div class="sidebar-user">
        <div class="card-body">
          <div class="media">
            <div class="mr-3">
              <router-link :to="{ name: 'Profile' }">
                <img
                  src="../assets/global_assets/images/image.png"
                  width="38"
                  height="38"
                  class="rounded-circle"
                  alt=""
                />
              </router-link>
            </div>

            <div class="media-body">
              <div class="media-title font-weight-semibold">
                {{ user.first_name || "" }} {{ user.last_name || "" }}
              </div>
              <div class="font-size-xs opacity-50">
                <i class="icon-pin font-size-sm"></i>
                &nbsp;{{ user.country }}
              </div>
            </div>

            <div class="ml-3 align-self-center">
              <router-link :to="{ name: 'Profile' }" class="text-white">
                <i class="icon-cog3"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- /user menu -->

      <!-- Main navigation -->
      <div class="card card-sidebar-mobile">
        <ul class="nav nav-sidebar" data-nav-type="accordion">
          <!-- order action menus -->
          <span class="mb-2">
            <li
              v-for="(item, index) in actionMenuItems"
              :key="index + 'bmi'"
              class="nav-item shadow"
              :class="showSidebar ? ' ' : 'px-sm-2 py-1'"
            >
              <router-link
                :to="{ name: item.routeName }"
                class="nav-link"
                :class="showSidebar ? ' py-1' : 'bg-primary'"
                style="border-radius: 12px !important"
              >
                <i :class="item.icon"></i>
                <span>{{ item.text }}</span>
              </router-link>
            </li>
          </span>

          <!-- Main -->
          <!-- <li class="nav-item-header"><div class="text-uppercase font-size-xs line-height-xs">Main</div> <i class="icon-menu" title="Main"></i></li> -->

          <span>
            <li
              v-for="(item, index) in baseMenuItems"
              :key="index + 'bmi'"
              class="nav-item py-0"
            >
              <router-link :to="{ name: item.routeName }" class="nav-link">
                <i :class="item.icon"></i>
                <span>{{ item.text }}</span>
              </router-link>
            </li>
          </span>

          <!-- base menu -->

          <!-- <li class="nav-item">
            <a class="nav-link" data-toggle="modal" data-target="#tipModal">
              <i class="icon-medal"></i>
              <span>Tip Writer</span>
            </a>
          </li>-->

          <!-- order menu -->
          <li
            v-for="(item, index) in sidebarMenu"
            :key="index + 'om'"
            class="nav-item py-0"
          >
            <router-link
              @click="getSidebarMenu"
              :to="{ name: 'OrderListing', params: { status: item.value } }"
              class="nav-link"
            >
              <div class="d-inline-block icons-sm mr-1">
                <i class="icon-pencil"></i>
              </div>
              <div class="d-block w-100 text-left">
                <span
                  class="badge badge-pill float-right"
                  :class="`bg-${item.color || 'dark'}`"
                  >{{ item.no || 0 }}</span
                >
                <span class="text-left">{{ item.name }}</span>
              </div>
            </router-link>
          </li>

          <li class="nav-item">
            <a @click="doLogout" class="nav-link">
              <i class="icon-lock"></i>
              <span>Logout</span>
            </a>
          </li>
          <!-- /main -->
        </ul>
      </div>
      <!-- /main navigation -->
    </div>
    <!-- /sidebar content -->
  </div>
  <!-- /main sidebar -->
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import {
  actionMenuItems,
  baseMenuItems,
  orderMenuItems,
} from "./sidebar.model";
// import TipWriter from "./payments/modals/TipWriter.vue";

export default {
  name: "Sidebar",
  components: {
    // TipWriter,
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("sidebar", ["showSidebar", "sidebarMenu"]),
  },
  data() {
    return {
      actionMenuItems: actionMenuItems,
      baseMenuItems: baseMenuItems,
      orderMenuItems: orderMenuItems,
    };
  },
  // beforeRouteUpdate() {
  //   this.getSidebarMenu()
  // },
  mounted() {
    this.getSidebarMenu();
  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
    ...mapActions("sidebar", ["getSidebarMenu"]),
    ...mapMutations("sidebar", ["_toggleSidebarMobile"]),
  },
};
</script>


<style lang="scss" scoped>
.nav-item {
  max-height: 40px !important;
  height: 40px !important;
}
.nav-link {
  padding: 8px 10px !important;
}
.sidebar {
  .nav-link {
    padding: 0.5rem 0.8rem;
  }
}
.modal-backdrop {
  z-index: 1 !important;
}

.sidebar-xs .sidebar-main .nav-sidebar > .nav-item > .nav-link {
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
  max-height: 40px !important;
}
.nav-link.active.just-active {
  // background-color: rgba(202, 201, 201, 0.74) !important;
  // border-radius: 0px 20px 20px 0px;
  // transition: 0.3s all ease-in-out;
}

.sidebar-xs .sidebar {
  a.nav-link i {
    font-size: 22px;
    padding: 6px;
  }

  a.nav-link.active.just-active {
    // padding-top: 0px !important;
    // padding-bottom: 0px !important;
    // margin-top: 4px !important;
    // margin-bottom: 4px !important;
  }
}
</style>