<template>
    <div class="">
        <section  v-if="orders.length > 0"  class="content-main">
            <!-- {{orders}} -->
        <header class="orders__header d-sm-flex d-none header-without-form">
            <div class="orders__items  ">
            <div class="orders__id orders__label">
            Order id </div>
            <div class="orders__status orders__label">
            Order Details </div>
            <div class="orders__icon"></div>
            </div>
            <div class="orders__topic orders__label mt-sm-0 mt-3 ">Topic</div>
            <div class="orders__deadline mt-0">
            <span class="orders__deadline-link orders__label ">
                <span class="deadline-order-txt">Deadline</span>
                <span class="  arrows">
                <!-- <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.43431 12.5657C5.74673 12.8781 6.25327 12.8781 6.56569 12.5657L11.6569 7.47452C11.9693 7.1621 11.9693 6.65557 11.6569 6.34315C11.3444 6.03073 10.8379 6.03073 10.5255 6.34315L6 10.8686L1.47452 6.34315C1.1621 6.03073 0.655565 6.03073 0.343146 6.34315C0.0307264 6.65557 0.0307264 7.1621 0.343146 7.47452L5.43431 12.5657ZM5.2 0V12H6.8V0L5.2 0Z" fill="#3499CC"></path>
                </svg> -->
                </span>
            </span>
            </div>
        </header>
        <header  class="orders__header d-sm-none d-flex header-without-form">
            <h4 style="min-width; 300px !important;" class="order-info-mob-txt"> My orders</h4>
            
        </header>

        <div v-for="(order, index) in orders" :key="index + 'ords'" class="order-list">
        <section class="list__orders order-preview-info not_paid">
        <div class="list__orders-item">
        <div class="list__orders-id d-flex flex-column align-items-center justify-content-center">
          <router-link v-if="order.id" :to="{ name: 'Order', params: { id: order.id } }">{{order.id || 'Total' }} </router-link>
              <span v-else >{{order.id || 'Total' }} </span>
          <!-- <div class="px-2" v-if="isEditable">
            <input
              v-if="order.id"
              class="form-control ml-2"
              v-model="bulkActionIds"
              name="bulkActionIds"
              :id="`bulkActionIds${order.id}`"
              type="checkbox"
              :value="order.id"
              style="width: 20px !important; height: 20px !important"
            />
          </div> -->

        </div>
        <div class="list__orders-status d-flex  flex-column  ">
        <span v-if="order.id" class="d-flex py-2 t-order-status__text text-left "> Cost: <strong class="px-1" >${{order.cost}}</strong> </span>
        <span v-else class="d-flex py-2 t-order-status__text text-left ">  <strong class="px-1" >${{order.cost}}</strong> </span>
        <span v-if="order.subject_area_text  " class=" d-flex t-order-status__text text-left">Subject: <strong class="px-1"  >{{order.subject_area_text}}</strong></span>
        <span v-if="order.pages  " class=" py-2 t-order-status__text text-left ">{{order.pages}} page(s)</span>


        </div>
        <div class="list__orders-icons">
        </div>
        </div>
        <div class="list__orders-topic">
        <p v-if="order.id"  class="mob-txt">Topic:</p>
        <div class="topic-order-txt">
        {{order.title}}
        <div class="t-order-list__status-wrap">
        <div v-if="isEditable && order.id  " class="progress-state-text not_paid fs13">
        Your order has not been paid yet. </div>
        <div class="t-order-list__status-bar">
        <div class="t-order-list__status-bar__inner"></div>
        </div>
        </div>
        </div>
        </div>
        <div class="list__orders-deadline">
        <div class="deadline-flex">
        <p v-if="order.id"  class="mob-txt">Deadline:</p>
        <div :class="order.client_deadline && order.client_deadline.past_due ? 'text-danger' : 'text-success'" class="date-order  d-flex align-items-center justify-content-center ">
            
        <span class="px-1" >
        {{order.client_deadline && order.client_deadline.date}} </span>
        </div>
        </div>
        <div v-if="order.id" class="deadline-btn-flex">
        <router-link :to="{ name: 'Order', params: { id: order.id } }" class="item-link btn--outline d-flex align-items-center justify-content-center " title="Details of this order: type of work, subject, etc.">
        <span class="desc-txt">Details</span>

        </router-link>
        <router-link v-if="isEditable" :to="{ name: 'payment', params: { id: order.id } }" class="item-link my-2 btn_pay d-flex align-items-center justify-content-center " title="Details of this order: type of work, subject, etc.">
        <span class="desc-txt">Pay</span>

        </router-link>
        </div>
        </div>
        
        </section>
        </div>
        </section>

        
        
    </div>
</template>
  
  <script>
  export default {
    name: "Orderitem",
    props: {
      orders: {
        type: Object,
        required: false,
      },
      filters: {
        type: Object,
        required: false,
      },
    },
    computed: {
    isEditable() {
      if (this.$route.params.status == "unpaid") {
        return true;
      } else return false;
    },
  },
  };
  </script>
  
  <style>
  .has-pointer {
    cursor: pointer;

  }

  </style>