<template>
  <div class="is-loading d-flex justify-content-center align-items-center">
    <!-- <img width="60px" :src="loading_gif"  alt="Loading..."> -->
    <!-- 7 -->
    <div class="loader loader--style6" title="5" style="width:60px; height:60px">
      <span class="spinner" ></span>

    </div>
  </div>
</template>


<script>
// import loading_gif from "../assets/global_assets/images/loading.gif";
export default {
  name: "BaseLoading",
  data() {
    return {
      // loading_gif: loading_gif,
    };
  },
};
</script>


<style lang="scss">
svg path,
svg rect{
  fill: #009688 ;
}
</style>
