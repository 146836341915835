<template>
  <!-- Main navbar -->
<header  class="t-header">
<div class="pt-2 pb-1 container ">
<div class="t-header__container">
<div class="t-header__left t-header__left_top">
 <div class="t-logo ">
<div class="t-logo__mobile">
{{site_settings.name}} </div>
<div class="t-logo__normal">
<router-link  :to="{ name: 'Dashboard' }">{{site_settings.name}}</router-link> </div>
</div>
<!-- <div v-if="site_settings && Object.keys(site_settings.email).length   > 0 "  class="t-toll-free d-flex flex-row  align-items-center justify-content-center">
<span class="t-toll-free__text px-1 ">
<svg width="14" height="14" fill="#3399CC" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
</span>
<span>{{site_settings.email}}</span>
</div> -->
</div>
<div class=" t-header__right">
<div class="t-header__menu hidden-mobile">
<div class="t-header__menu-item">
<span class="t-header__light-text">Welcome,</span> Dear  {{user.first_name}} </div>
<div class="t-header__menu-item">
<router-link  :to="{ name: 'Wallet' }" class="user-balance">
  <img style="width:23px!important; " class="px-1" src="/orders/wallet-solid.svg" alt="setting">
Wallet: <span class="balance-sum" data-label="bonus-balance">
  ${{ wallet_balance.balance || 0 }}</span>
</router-link>
</div>
<div class="t-header__menu-item">
<div class="updates-news">
<span>
  <img style="width:25px!important; " class="px-1" src="/orders/bell-solid.svg" alt="setting">

Notifications </span>
</div>
</div>

<div class="t-header__menu-item">
<img style="width:25px!important; " class="px-1" src="/orders/gear-solid.svg" alt="setting">
<div>
<router-link :to="{name: 'Profile'  } " title="Your personal info and password.">Settings</router-link> 
</div>
</div>
</div>
</div>

<div class=" t-header__right-sm">
  <button @click="toggleSidebar()" id="toggle-button"  class="navbar-toggler sidebar-toggle sidebar-mobile-main-toggle" type="button">
    <img style="width:25px!important; " class="px-1" src="/orders/menu-solid.svg" alt="toggle menu">
    <!-- <i class="icon-paragraph-justify3"></i> -->

  </button>
</div>
</div>
</div>
<div class="container pb-2 t-header__container_bottom">
<div class="t-header__left t-header__container">
<router-link :to="{name: 'PlaceOrder'  } " id="track-new-order" class="t-btn t-btn_blue  px-sm-4 px-2 t-btn_icon"  title="Order Page, where you can make a new order."> <img style="width:25px!important; " class="px-1" src="/orders/placeorder-solid.svg"  alt="place order"> CREATE NEW ORDER</router-link> 
 </div>
<div class="t-header__right t-header__messages">
</div>
</div>
<!-- </div> -->
</header>
  <!-- <div class="navbar navbar-expand-md navbar-dark fixed-top">
    <div class="navbar-brand">
      <a href="/" class="d-inline-block">
        <img src="/logo.png" alt="Home"/>
      </a>
    </div>

    <div class="d-md-none">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar-mobile"
      >
        <i class="icon-tree5"></i>
      </button>
      <button @click="_toggleSidebarMobile" class="navbar-toggler sidebar-mobile-main-toggle" type="button">
        <i class="icon-paragraph-justify3"></i>
      </button>
    </div>

    <div class="collapse navbar-collapse" id="navbar-mobile">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a
            href="#"
            class="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block"
             @click="_toggleSidebar" 
          >
            <i class="icon-paragraph-justify3"></i>
          </a>
        </li>
      </ul>

      <ul class="navbar-nav ml-auto">
        

        <li class="nav-item dropdown dropdown-user">
          <a
            href="#"
            class="navbar-nav-link d-flex align-items-center dropdown-toggle"
            data-toggle="dropdown"
          >
            <img
              src="../assets/global_assets/images/image.png"
              class="rounded-circle mr-2"
              height="34"
              alt=""
            />
            <span>
              {{ user.first_name || "" }} {{user.last_name || ""}}
            </span>
          </a>

          <div class="dropdown-menu dropdown-menu-right">
            <router-link :to="{ name: 'Profile' }" class="dropdown-item"
              ><i class="icon-user-plus"></i> My profile</router-link>
            <router-link  :to="{ name: 'Wallet' }" class="dropdown-item"
              ><i class="icon-coins"></i> My balance</router-link>
            <router-link :to="{ name: 'Messages' }" class="dropdown-item"><i class="icon-comment-discussion"></i> Messages
            </router-link>
            <div class="dropdown-divider"></div>
           
            <a @click="doLogout" class="dropdown-item"
              ><i class="icon-switch2"></i> Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </div> -->
  <!-- /main navbar -->
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Navbar",
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("setups", ["countries", "site_settings"]),
    ...mapState("payment", ["wallet_entries", "loading", "wallet_balance"])


  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
    ...mapMutations("sidebar", ["_toggleSidebar", "_toggleSidebarMobile"]),
    ...mapActions("setups", ["getSetups","getSiteSettings", "getCountries"]),
    ...mapActions("payment", ["_getWalletEntries", "_getWalletBalance"]),
    toggleSidebar() {
          var element = document.getElementById("aside_new");
          element.classList.toggle("d-block");

          // var elementNew = document.getElementById("expanded__container");
          // elementNew.classList.toggle("expanded")
        }


  },
  async mounted() {
    this.$store.state.loading = true;
    this.getSiteSettings()
    await this._getWalletEntries()
    await this._getWalletBalance()
    this.$store.state.loading = false;
  },
 
};
</script>
